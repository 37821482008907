<template>
  <v-dialog
    v-model="visible"
    class="project-modal"
    scrollable
    max-width="750"
  >
    <v-card
      v-if="action === 'delete'"
      class="pa-6"
    >
      <v-card-title primary-title>
        <div class="headline darkestblue--text">
          Are you sure?
        </div>
        <v-card-actions>
          <v-btn
            :ripple="false"
            icon
            @click.stop="close()"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-card-text>
        <div class="coolgray--text">
          <v-row>
            <v-col class="subtitle-2">
              ID
            </v-col>
            <v-col class="body-1">
              {{ entry.project_id }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Project
            </v-col>
            <v-col class="body-1">
              {{ entry.project_name }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Code
            </v-col>
            <v-col class="body-1">
              {{ entry.project_code }}
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          text
          color="coolgray"
          @click.stop="cancelAction"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          @click.stop="submitProjectAction"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="['create', 'update'].includes(action)"
      class="pa-4"
    >
      <v-card-title>
        <span>
          {{ action === 'create' ? 'Add' : 'Edit' }} Project
        </span>

        <v-card-actions>
          <v-btn
            :ripple="false"
            icon
            @click.stop="close()"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="entry.project_code"
              label="Code"
              name="project_code"
              type="textual"
            />

            <v-text-field
              v-model="entry.project_name"
              label="Name"
              name="project_name"
              type="textual"
            />

            <v-row>
              <v-col>
                <v-button
                  text
                  @click="showThresholdForm('create')"
                >
                  <span>Add Threshold</span>
                </v-button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card-actions class="justify-end">
              <v-btn
                text
                color="coolgray"
                @click.stop="cancelAction"
              >
                Cancel
              </v-btn>

              <v-btn
                color="primary"
                @click.stop="submitProjectAction"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('internal')

export default {
  name: 'ProjectModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    thresholdForm: null,
    loading: false,
  }),
  computed: {
    visible: {
      get() {
        return this.isProjectModalVisible
      },
      set(value) {
        this.$store.commit('internal/isProjectModalVisible', value)
      },
    },
    ...mapGetters(['errorMessage', 'successMessage']),
    ...mapState({
      action: (state) => state.action,
      didLoadProjectFail: (state) => state.didLoadProjectFail,
      entry: (state) => state.entry,
      isLoadingProject: (state) => state.isLoadingProject,
      isProjectModalVisible: (state) => state.isProjectModalVisible,
      projects: (state) => state.projects,
      selected: (state) => state.selected,
    }),
    viewProject() {
      const {
        projects,
        didLoadProjectFail,
        isLoadingProject,
      } = this

      return isLoadingProject === false
        && didLoadProjectFail === false
        && projects.length > 0
    },
    noProjectData() {
      const {
        projects,
        isLoadingProject,
        didLoadProjectFail,
      } = this
      const requestComplete = isLoadingProject === false
      const requestSucceeded = didLoadProjectFail === false
      const noResults = projects.length === 0

      return requestComplete && requestSucceeded && noResults
    },
  },
  methods: {
    hideThresholdForm() {
      this.thresholdForm = null
    },
    showThresholdForm(action, entryThreshold = {}) {
      if (action === 'update') {
        this.thresholdForm = entryThreshold
      } else {
        this.thresholdForm = {
          // Identifiers
          name: String,
          code: String, // User input of an alphanumeric value matching the TEST1234 pattern. Unique.

          // Change Logs
          updated: [{ date: Date, by: String }], // by === currentUser.email
          created: [{ date: Date, by: String }], // by === personUser.email

          // Dates
          startDate: Date,
          endDate: Date,

          watch: {
            collectionName: 'project', // Collection Name: String: [organization, person, project, service, team, time]
            property: 'projectCode', // Collection Code: String: [billingCode, personId, projectCode, serviceCode, teamCode]
            value: 'TEST1234', // A string to be used as a regex test on the given property, e.g. projectCode.
            operator: 'eq',
          },
          cause: [
            {
              dateRange: { startDate: Date, endDate: Date },
              // Update form input types and options based on context like page and user selections in the form.
              // Option Groups: [Boolean, Numerical, Textual, Strict (N/EQ), Custom]
              if: 'add', // Event: ['add', 'inactive', 'remove']
              is: true, // Value: Any: [user input, presets based on operator]
              for: 'yield partner', // ['demand source', 'network', 'yield partner']
              operator: 'and', // ['and', 'or']
            },
            {
              // @TODO Confirm operator at dateRange during user sessions.
              dateRange: { startDate: Date, endDate: Date }, // >= 14 days
              if: 'total',
              is: 0,
              for: 'delivery',
              operator: 'and', // ['and', 'or']
            },
          ],
          effect: [
            {
              then: 'email', // ['alert in-app', 'email', 'slack']
              endpoints: ['accounts@adops.com', 'bts@adops.com'], // [personUser.email]
              frequency: 'daily', // Frequency Cap: [once, daily, weekly, monthly]
              schedule: 'immediately', // [immediately, daily, weekly, monthly]
            },
            {
              then: 'email', // ['alert in-app', 'email', 'slack']
              endpoints: ['example.internal@adops.com'], // [personUser.email]
              frequency: 'once', // Frequency Cap: [daily, once]
              schedule: 'weekly', // [immediately, daily, weekly, monthly]
            },
          ],

          filter: [
            { operator: 'neq', property: 'name', value: 'Yield Partner A' },
            { operator: 'neq', property: 'type', value: 'Sponsorship' },
          ],
        }
      }
    },
    hideProjectForm() {
      this.projectForm = null
    },
    showProjectForm(action, entryProject = {}) {
      if (action === 'update') {
        this.projectForm = entryProject
      } else {
        this.projectForm = {
          // Identifiers
          name: String,
          code: String,

          // Change Logs
          // Update change log separately to avoid performance issues.
          updated: [{
            // endpointName: 'project',
            // endpointCode: 'TEST2021',
            by: String, // by === currentUser.email
            date: Date,
            snapshot: String, // JSON.stringify(entry)
          }],

          // Dates
          startDate: Date,
          endDate: Date,

          contract: [
            {
              type: String, // []
              hours: Number,
            },
          ],
        }
      }
    },
    close() {
      this.$store.commit('internal/isProjectModalVisible', false)
    },

    async submitProjectAction() {
      const { action, entry } = this
      const { commit, dispatch } = this.$store
      // @TODO: Actually map the action_type to what it's meant to be.
      const map = {
        create: 'ADD',
        update: 'UPDATE',
        delete: 'DELETE',
      }

      entry.action_type = map[action]

      const response = await dispatch('internal/submitProjectAction', entry)
      const { content = [], error } = response || {}

      if (error || content?.code || !response) {
        const message = error?.message || content?.message || content?.code || 'Unknown API Error'

        commit('tools/snackbar', true)
        commit('tools/snack', { message, status: 'error' })

        return error || content
      }

      const [entryId] = content
      const status = entryId ? 'success' : 'error'
      const message = entryId ? this.successMessage : this.errorMessage

      if (status === 'success') {
        this.close()
      }

      commit('tools/snackbar', true)
      commit('tools/snack', { message, status })

      return response
    },
    cancelAction() {
      const { selected } = this

      this.$store.commit('internal/action', 'read')
      this.$store.commit('internal/entry', Object.create(selected))

      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

::v-deep.v-card {
  border-radius: 6px;
  border-width: 0;
}

::v-deep.v-card__title {
  color: $color-monochrome-darkestblue;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.51px;
  line-height: 22px;
  justify-content: space-between;
}

::v-deep.v-card__text {
  padding: 0;
}

.v-card + .v-card {
  margin-top: 1ch;
}
</style>
